//contact.scss

// Page Tabs & Content (!global)
.tablinks {
	li {
		p,
		span {
			color: map-get($colors, 'inactive-grey');
		}
		&::after {
			background-color: map-get($colors, 'text');
		}
		&.active {
			p,
			span {
				color: map-get($colors, 'text');
			}
		}
		&:hover {
			p,
			span {
				color: map-get($colors, 'text');
			}
		}
	}
	
	&.sticky {
		@include mobile {
			background-color: map-get($colors, 'bg');
		}
	}
	
}
.tablist {
	&__mobile {
		&__nav {
			li {
				color: map-get($colors, 'inactive-grey');
				span {
					color: map-get($colors, 'inactive-grey');
				}
				&.active,
				&:hover,
				&.active span,
				&:hover span {
					color: map-get($colors, 'text');
				}
			}
		}
	}
}
.tabcontent {
	&__item {
		border-bottom-color: map-get($colors, 'text');
		&__img {
			background-color: map-get($colors, 'light-grey');
			img {
				background-color: transparent;
			}
		}
		&__info {
			&-link {
				svg {
					path {
						stroke: map-get($colors, 'text');
					}
				}
			}
		}
	}
}
// Page Tabs & Content (END)

.contact {
	.tabcontent {
		.content {
			&__item {
				&-line {
					background-color: map-get($colors, 'text');
				}
			}
		}
	}
	#map * {
		background: initial;
	}
}
