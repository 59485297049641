@import '../asset-helpers-base';

$path: $themePath;
$invertPath: $invertThemePath;
:root {
	--textColor: #{map-get($colors, 'text')};
	--bg: #{map-get($colors, 'bg')};
	--inactive: #{map-get($colors, 'inactive-grey')};
	--invertImage: 1;
	--light: #{map-get($colors, 'light-grey')};
}
* {
	color: map-get($colors, 'text');
}
#page {
	background-color: map-get($colors, 'bg');
}

body.overflow {
	#header,
	#adjuster {
		background-color: map-get($colors, 'bg');
	}
}
#header {
	@include mobile {
		background-color: map-get($colors, 'bg');
	}
	background: map-get($colors, 'bg-gradient');
}
#adjuster {
	@include mobile {
		background-color: map-get($colors, 'bg');
	}
	background: map-get($colors, 'bg-gradient-reverse');
}
.text-404 {
	color: map-get($colors, 'light-grey');
}
.search__field {
	&::placeholder {
		color: map-get($colors, 'text');
	}
}
.interior-view {
	background-color: map-get($colors, 'light-grey');
}
.popup {
	&__share {
		.social {
			& > * {
				&:nth-child(1) {
					.icon {
						background-image: url($path + 'icons/24px/ic_facebook.svg');
					}
				}
				&:nth-child(2) {
					.icon {
						background-image: url($path + 'icons/24px/ic_twitter.svg');
					}
				}
				&:nth-child(3) {
					.icon {
						background-image: url($path + 'icons/24px/ic_pinterest.svg');
					}
				}
				&:nth-child(4) {
					.icon {
						background-image: url($path + 'icons/24px/ic_mail.svg');
					}
				}
			}
		}
	}
}

.form-group {
	input,
	textarea {
		color: map-get($colors, 'text');
		&::placeholder {
			color: map-get($colors, 'inactive-grey');
		}
	}
	&.disabled {
		input,
		textarea {
			color: map-get($colors, 'inactive-grey');
		}
		border-bottom-color: map-get($colors, 'inactive-grey');
	}
	&.invalid {
		border-bottom-color: map-get($colors, 'invalid');
		color: map-get($colors, 'invalid');
	}
}

.form-file {
	.text-xs {
		color: map-get($colors, 'inactive-grey');
	}
}

.btn-back {
	color: map-get($colors, 'inactive-grey');
	&::before {
		content: '';
		background-color: map-get($colors, 'inactive-grey');
		mask-image: url($path + 'icons/24px/ic_arrow-left.svg');
	}
}
.btn-arrow {
	&,
	&-container > * {
		&::after {
			content: '';
			background-image: url($path + 'buttons/hover-arrow-xl.svg');
		}
	}
}

.section-grey {
	background-color: map-get($colors, 'light-grey');
}

.header {
	&__logo {
		a {
			background-image: url($path + 'logos/logo-horizontal.svg');
		}
	}

	&.open {
		.header {
			&__burger {
				@include hover {
					&:hover {
						.header__burger__button {
							background-image: url($invertPath + 'icons/24px/ic_close.svg');
						}
					}
				}

				&__button {
					background-image: url($path + 'icons/24px/ic_close.svg');
				}
			}
		}
	}
	&:not(.open) {
		.header {
			&__burger {
				@include hover {
					&:hover {
						.header__burger__button {
							background-image: url($invertPath + 'icons/24px/ic_hamburger.svg');
						}
					}
				}

				&__button {
					background-image: url($path + 'icons/24px/ic_hamburger.svg');
				}
			}
		}
	}

	&__nav {
		&__button {
			background-color: map-get($colors, 'text');
		}
		&__mobile {
			background-color: map-get($colors, 'bg');
			&,
			& .nav__search {
				&::before {
					background-color: map-get($colors, 'text');
				}
			}
		}
	}
}

.search {
	&__icon {
		background-image: url($path + 'icons/20px/ic_search.svg');
	}
}

.dot {
	&::after {
		background-color: map-get($colors, 'dot');
	}
}

.vertical-separator {
	background-color: map-get($colors, 'text');
}

.language-switcher {
	&__button {
		.check-slider {
			border: 1px solid map-get($colors, 'text');
			&::before {
				background-color: map-get($colors, 'text');
			}
		}
	}
}

.theme-switcher {
	&__button {
		border-color: map-get($colors, 'text');
		//background-color: map-get($colors, 'bg');
		&__fill {
			background-color: map-get($colors, 'text');
		}
	}
}

.subscribe {
	&::after {
		background-image: url($path + 'icons/24px/ic_arrow-right.svg');
	}
}

.footer {
	background-color: map-get($colors, 'light-grey');
	&__logo {
		background-image: url($path + 'logos/logo-vertical.svg');
	}
	&__madeby {
		&__logo {
			background-image: url($path + 'logos/logo-bachoo.svg');
		}
	}
}

.slider {
	&-btn {
		&::before {
			background-image: url($invertPath + 'buttons/hover-arrow-xl.svg');
		}
	}
	&__control {
		&__prev,
		&__next {
			border-color: map-get($colors, 'text');
		}
		&__prev {
			&__icon {
				background-image: url($path + 'icons/24px/ic_arrow-left.svg');
			}
		}
		&__next {
			&__icon {
				background-image: url($path + 'icons/24px/ic_arrow-right.svg');
			}
		}
	}
}

.border {
	&,
	&-top,
	&-bottom {
		&::before,
		&::after {
			background-color: map-get($colors, 'text');
		}
	}
}

.cursor {
	fill: map-get($colors, 'text');
	&.invert {
		filter: invert(1);
	}
	&:not(.invert) {
		filter: invert(0);
	}
	&.arrow,
	&.video,
	&.cursor-404 {
		stroke: map-get($colors, 'bg');
	}
}

.button-404__mobile {
	background-color: map-get($colors, 'text');
	color: map-get($colors, 'bg');
}

.share__button__icon.invert.chat__button .share__button__text {
	svg {
		path {
			fill: map-get($colors, 'text');
			stroke: map-get($colors, 'bg');
		}
	}
}

// Arrow-move
.arrow-move__wrapp {
	svg {
		path {
			stroke: map-get($colors, 'text');
		}
	}
}
