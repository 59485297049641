.popup {
    &__new,
    &__share {
        background-color: map-get($colors, 'bg');
    }
    &__close {
        svg {
            path {
                stroke: map-get($colors, 'inactive-grey');
            }
        }
    }
    .form {
        label {
            color: map-get($colors, 'inactive-grey');
        }
        input {
            border-bottom-color: map-get($colors, 'text');
            &::placeholder {
                color: map-get($colors, 'inactive-grey');
            }
        }
    }
    &__img {
        background-color: map-get($colors, 'light-grey');
    }
    .social {
        & > * {
            border-color: map-get($colors, 'text');
        }
    }
}