$themePath: '../img/light/';
$invertThemePath: '../img/dark/';
$colors: (
	'text': #000000,
	'bg': #ffffff,
	'dot': #f83636,
	'invalid': #f83636,
	'inactive-grey': #a7a7a7,
	'dark-grey': #161616,
	'light-grey': #f3f3f3,
	'bg-gradient': linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.5) 100%),
	'bg-gradient-reverse':
		linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 1) 100%),
	'bg-sitebar': linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%)
);
$filters: (
	'invert': invert(0)
);
