.single {
	.tabcontent__single {
		.content {
			.line {
				background-color: map-get($colors, 'text');
			}
			ul {
				li {
					&:after {
						background-color: map-get($colors, 'text');
					}
				}
			}
			.blockquote {
				background: linear-gradient(
					90deg,
					map-get($colors, 'light-grey') 0%,
					map-get($colors, 'light-grey') 60%,
					rgba(0, 0, 0, 0) 60%
				);
			}
		}
	}
}

.sitebar {
	.accordion {
		&-title {
			div {
				border-color: map-get($colors, 'text');
				span {
					background-color: map-get($colors, 'text');
				}
			}
		}
	}
	&__line {
		background-color: map-get($colors, 'text');
	}
}

.video {
	&__wrapper {
		.video-btn {
			background-color: map-get($colors, 'text');
		}
	}
}

@include mobile {
	.sitebar {
		.share {
			background-color: map-get($colors, 'bg');
			border-color: map-get($colors, 'text');
		}
	}
}
