.search-page {
    .search {
        &__wrapp__input {
            &::after {
                background-color: map-get($colors, 'text');
            }
        }
        &__input {
            &::placeholder {
                color: map-get($colors, 'inactive-grey');
            }
        }
        &__vals {
            &__result {
                li {
                    color: map-get($colors, 'inactive-grey');
                    &:hover {
                        color: map-get($colors, 'text');
                    }
                }
            }
        }
        &__submit {
            border-color: map-get($colors, 'text');
            svg path {
                stroke: map-get($colors, 'text');
            }
        }
        &__popular {
            h6 {
                color: map-get($colors, 'inactive-grey');
            }
        }
    }
}