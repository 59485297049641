/*
p {
	@include text-gradient((260deg, #666, #000));
}
*/
/*
p {
	@include text-gradient((260deg, #666, #000));
}
*/
:root {
  --textColor: #000000;
  --bg: #ffffff;
  --inactive: #a7a7a7;
  --invertImage: 1;
  --light: #f3f3f3;
}

* {
  color: #000000;
}

#page {
  background-color: #ffffff;
}

body.overflow #header,
body.overflow #adjuster {
  background-color: #ffffff;
}

#header {
  background: linear-gradient(180deg, white 0%, rgba(255, 255, 255, 0.5) 100%);
}

@media only screen and (max-width: 575px) {
  #header {
    background-color: #ffffff;
  }
}

#adjuster {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, white 100%);
}

@media only screen and (max-width: 575px) {
  #adjuster {
    background-color: #ffffff;
  }
}

.text-404 {
  color: #f3f3f3;
}

.search__field::placeholder {
  color: #000000;
}

.interior-view {
  background-color: #f3f3f3;
}

.popup__share .social > *:nth-child(1) .icon {
  background-image: url("../img/light/icons/24px/ic_facebook.svg");
}

.popup__share .social > *:nth-child(2) .icon {
  background-image: url("../img/light/icons/24px/ic_twitter.svg");
}

.popup__share .social > *:nth-child(3) .icon {
  background-image: url("../img/light/icons/24px/ic_pinterest.svg");
}

.popup__share .social > *:nth-child(4) .icon {
  background-image: url("../img/light/icons/24px/ic_mail.svg");
}

.form-group input,
.form-group textarea {
  color: #000000;
}

.form-group input::placeholder,
.form-group textarea::placeholder {
  color: #a7a7a7;
}

.form-group.disabled {
  border-bottom-color: #a7a7a7;
}

.form-group.disabled input,
.form-group.disabled textarea {
  color: #a7a7a7;
}

.form-group.invalid {
  border-bottom-color: #f83636;
  color: #f83636;
}

.form-file .text-xs {
  color: #a7a7a7;
}

.btn-back {
  color: #a7a7a7;
}

.btn-back::before {
  content: '';
  background-color: #a7a7a7;
  mask-image: url("../img/light/icons/24px/ic_arrow-left.svg");
}

.btn-arrow::after,
.btn-arrow-container > *::after {
  content: '';
  background-image: url("../img/light/buttons/hover-arrow-xl.svg");
}

.section-grey {
  background-color: #f3f3f3;
}

.header__logo a {
  background-image: url("../img/light/logos/logo-horizontal.svg");
}

@media only screen and (hover: hover) and (pointer: fine) {
  .header.open .header__burger:hover .header__burger__button {
    background-image: url("../img/dark/icons/24px/ic_close.svg");
  }
}

.header.open .header__burger__button {
  background-image: url("../img/light/icons/24px/ic_close.svg");
}

@media only screen and (hover: hover) and (pointer: fine) {
  .header:not(.open) .header__burger:hover .header__burger__button {
    background-image: url("../img/dark/icons/24px/ic_hamburger.svg");
  }
}

.header:not(.open) .header__burger__button {
  background-image: url("../img/light/icons/24px/ic_hamburger.svg");
}

.header__nav__button {
  background-color: #000000;
}

.header__nav__mobile {
  background-color: #ffffff;
}

.header__nav__mobile::before,
.header__nav__mobile .nav__search::before {
  background-color: #000000;
}

.search__icon {
  background-image: url("../img/light/icons/20px/ic_search.svg");
}

.dot::after {
  background-color: #f83636;
}

.vertical-separator {
  background-color: #000000;
}

.language-switcher__button .check-slider {
  border: 1px solid #000000;
}

.language-switcher__button .check-slider::before {
  background-color: #000000;
}

.theme-switcher__button {
  border-color: #000000;
}

.theme-switcher__button__fill {
  background-color: #000000;
}

.subscribe::after {
  background-image: url("../img/light/icons/24px/ic_arrow-right.svg");
}

.footer {
  background-color: #f3f3f3;
}

.footer__logo {
  background-image: url("../img/light/logos/logo-vertical.svg");
}

.footer__madeby__logo {
  background-image: url("../img/light/logos/logo-bachoo.svg");
}

.slider-btn::before {
  background-image: url("../img/dark/buttons/hover-arrow-xl.svg");
}

.slider__control__prev, .slider__control__next {
  border-color: #000000;
}

.slider__control__prev__icon {
  background-image: url("../img/light/icons/24px/ic_arrow-left.svg");
}

.slider__control__next__icon {
  background-image: url("../img/light/icons/24px/ic_arrow-right.svg");
}

.border::before, .border::after, .border-top::before, .border-top::after, .border-bottom::before, .border-bottom::after {
  background-color: #000000;
}

.cursor {
  fill: #000000;
}

.cursor.invert {
  filter: invert(1);
}

.cursor:not(.invert) {
  filter: invert(0);
}

.cursor.arrow, .cursor.video, .cursor.cursor-404 {
  stroke: #ffffff;
}

.button-404__mobile {
  background-color: #000000;
  color: #ffffff;
}

.share__button__icon.invert.chat__button .share__button__text svg path {
  fill: #000000;
  stroke: #ffffff;
}

.arrow-move__wrapp svg path {
  stroke: #000000;
}

.about__about .about__content-line {
  background-color: #000000;
}

.about .tabcontent__item__img {
  background-color: transparent;
}

.tablinks li p,
.tablinks li span {
  color: #a7a7a7;
}

.tablinks li::after {
  background-color: #000000;
}

.tablinks li.active p,
.tablinks li.active span {
  color: #000000;
}

.tablinks li:hover p,
.tablinks li:hover span {
  color: #000000;
}

@media only screen and (max-width: 575px) {
  .tablinks.sticky {
    background-color: #ffffff;
  }
}

.tablist__mobile__nav li {
  color: #a7a7a7;
}

.tablist__mobile__nav li span {
  color: #a7a7a7;
}

.tablist__mobile__nav li.active, .tablist__mobile__nav li:hover,
.tablist__mobile__nav li.active span,
.tablist__mobile__nav li:hover span {
  color: #000000;
}

.tabcontent__item {
  border-bottom-color: #000000;
}

.tabcontent__item__img {
  background-color: #f3f3f3;
}

.tabcontent__item__img img {
  background-color: transparent;
}

.tabcontent__item__info-link svg path {
  stroke: #000000;
}

.contact .tabcontent .content__item-line {
  background-color: #000000;
}

.contact #map * {
  background: initial;
}

.museum-loans .tabcontent__item__img img {
  filter: invert(0);
}

.share__button__icon:not(.invert) {
  border-color: #000000;
}

.share__button__icon:not(.invert) .share__button__text span {
  color: #ffffff;
}

.share__button__icon:not(.invert) .share__button__text svg path {
  fill: #000000;
  stroke: #000000;
}

.share__button__icon.invert {
  background-color: #000000;
}

.share__button__icon.invert .share__button__text span {
  color: #000000;
}

.share__button__icon.invert .share__button__text svg path {
  fill: #ffffff;
  stroke: #ffffff;
}

.share__button__text span {
  color: #ffffff;
}

.share__button__text svg path {
  fill: #000000;
  stroke: #000000;
}

.share__button,
.submit {
  background-color: #000000;
  color: #ffffff;
}

.single .tabcontent__single .content .line {
  background-color: #000000;
}

.single .tabcontent__single .content ul li:after {
  background-color: #000000;
}

.single .tabcontent__single .content .blockquote {
  background: linear-gradient(90deg, #f3f3f3 0%, #f3f3f3 60%, rgba(0, 0, 0, 0) 60%);
}

.sitebar .accordion-title div {
  border-color: #000000;
}

.sitebar .accordion-title div span {
  background-color: #000000;
}

.sitebar__line {
  background-color: #000000;
}

.video__wrapper .video-btn {
  background-color: #000000;
}

@media only screen and (max-width: 575px) {
  .sitebar .share {
    background-color: #ffffff;
    border-color: #000000;
  }
}

.artwork__sitebar .line {
  background-color: #000000;
}

.interior-view:not(.zoom) {
  background-image: url("../img/interior-view.webp");
}

.breadcrumbs a {
  color: #a7a7a7;
}

@media only screen and (hover: hover) and (pointer: fine) {
  .breadcrumbs a:hover {
    color: #000000;
  }
}

.breadcrumbs svg path {
  stroke: #a7a7a7;
}

.breadcrumbs span {
  color: #000000;
}

.picture-bg::before {
  background-color: #f3f3f3;
}

.picture-bg .cover > a {
  color: white;
  border-color: white;
}

.picture-bg .cover > a:hover {
  color: #ffffff;
  border-color: #000000;
  background-color: #000000;
}

@media only screen and (max-width: 991px) {
  .picture-bg .cover > a {
    color: #000000;
  }
  .picture-bg .cover > a:hover {
    color: #000000;
    border-color: transparent;
    background-color: transparent;
  }
  .picture-bg svg {
    stroke: #000000;
  }
}

@media only screen and (max-width: 575px) {
  .artwork__sitebar .share {
    background-color: #ffffff;
  }
}

@media only screen and (min-width: 576px) {
  .artwork__sitebar .share {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
  }
}

@media only screen and (max-width: 767px) {
  .artwork__sitebar {
    border-color: #000000;
  }
  .artwork__sitebar .share {
    border-color: #000000;
  }
}

.popup__new, .popup__share {
  background-color: #ffffff;
}

.popup__close svg path {
  stroke: #a7a7a7;
}

.popup .form label {
  color: #a7a7a7;
}

.popup .form input {
  border-bottom-color: #000000;
}

.popup .form input::placeholder {
  color: #a7a7a7;
}

.popup__img {
  background-color: #f3f3f3;
}

.popup .social > * {
  border-color: #000000;
}

.search-page .search__wrapp__input::after {
  background-color: #000000;
}

.search-page .search__input::placeholder {
  color: #a7a7a7;
}

.search-page .search__vals__result li {
  color: #a7a7a7;
}

.search-page .search__vals__result li:hover {
  color: #000000;
}

.search-page .search__submit {
  border-color: #000000;
}

.search-page .search__submit svg path {
  stroke: #000000;
}

.search-page .search__popular h6 {
  color: #a7a7a7;
}
