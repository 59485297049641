//about.scss

.about {
    &__about {
        .about__content {
            &-line {
                background-color: map-get($colors, 'text');
            }
        }
    }
    .tabcontent {
        &__item {
            &__img {
                background-color: transparent;
            }
        }
    }
}