.share__button__icon {
	&:not(.invert) {
		border-color: map-get($colors, 'text');
		.share__button {
			&__text {
				span {
					color: map-get($colors, 'bg');
				}
				svg {
					path {
						fill: map-get($colors, 'text');
						stroke: map-get($colors, 'text');
					}
				}
			}
		}
	}
	&.invert {
		background-color: map-get($colors, 'text');
		.share__button {
			&__text {
				span {
					color: map-get($colors, 'text');
				}
				svg {
					path {
						fill: map-get($colors, 'bg');
						stroke: map-get($colors, 'bg');
					}
				}
			}
		}
	}
}

.share__button {
	&__text {
		span {
			color: map-get($colors, 'bg');
		}
		svg {
			path {
				fill: map-get($colors, 'text');
				stroke: map-get($colors, 'text');
			}
		}
	}
}
.share__button,
.submit {
	background-color: map-get($colors, 'text');
	color: map-get($colors, 'bg');
}
