.artwork {
	&__sitebar {
		.line {
			background-color: map-get($colors, 'text');
		}
	}
}
.interior-view {
	&:not(.zoom) {
		background-image: url('../img/interior-view.webp');
	}
}
.breadcrumbs {
	a {
		color: map-get($colors, 'inactive-grey');
		@include hover {
			&:hover {
				color: map-get($colors, 'text');
			}
		}
	}
	svg {
		path {
			stroke: map-get($colors, 'inactive-grey');
		}
	}
	span {
		color: map-get($colors, 'text');
	}
}

.picture-bg {
	&::before {
		background-color: map-get($colors, 'light-grey');
	}
	.cover {
		& > a {
			color: white;
			border-color: white;
			&:hover {
				color: map-get($colors, 'bg');
				border-color: map-get($colors, 'text');
				background-color: map-get($colors, 'text');
			}
		}
	}
}

// Media
// $screen-lg: 992px;
@include mobile-tablet {
	.picture-bg {
		.cover {
			& > a {
				color: map-get($colors, 'text');
				&:hover {
					color: map-get($colors, 'text');
					border-color: transparent;
					background-color: transparent;
				}
			}
		}
		svg {
			stroke: map-get($colors, 'text');
		}
	}
}

@include mobile {
	.artwork__sitebar {
		.share {
			background-color: map-get($colors, 'bg');
		}
	}
}
@include tablet-desktop {
	.artwork__sitebar {
		.share {
			background: map-get($colors, 'bg-sitebar');
		}
	}
}
// $screen-md: 768px;
@include mobile-md {
	.artwork {
		&__sitebar {
			border-color: map-get($colors, 'text');
			.share {
				border-color: map-get($colors, 'text');
				// @include mobile-md {
				// 	background-color: transparent;
				// }
			}
		}
	}
}
