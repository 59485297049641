// .container creation
@mixin make-container() {
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}

//make single media rule for list of values
//$args - media params -> (min-width: 576px) and (max-width:1399px)
//$selectors - list with params -> (h1: (24px, 32px, 'Times New Roman', 500), h1: (14px, 22px, 'Times New Roman', 400) ... )
//$index - sublist selection
@mixin applyMedia($args, $selectors, $index) {
	@media #{$args} {
		@each $selector, $text in nth($selectors, $index) {
			#{$selector},
			#{'.' + $selector} {
				& {
					font-size: nth($text, 1);
					line-height: nth($text, 2);
					font-family: nth($text, 3);
					font-weight: nth($text, 4);
				}
			}
		}
	}
}

//similar to previous
//work with 4 or 5 font params
@mixin applyMediaSimple($args, $selectors, $index) {
	@media #{$args} {
		@each $selector, $text in $selectors {
			$count: 0;
			@each $param in nth($text, $index) {
				$count: $count + 1;
			}
			#{$selector} {
				& {
					font-size: nth(nth($text, $index), 1);
					line-height: nth(nth($text, $index), 2);
					font-family: nth(nth($text, $index), 3);
					font-weight: nth(nth($text, $index), 4);
					@if $count > 4 {
						letter-spacing: nth(nth($text, $index), 5);
					}
				}
			}
		}
	}
}

//h1-h6 style mixin
//accept only tag name and 4 font params
//example: _font-style.scss -> $headingDesktop
//example: _font-style.scss -> $headingLaptop
//example: _font-style.scss -> $headingMobile
//$selectors: $headingDesktop, $headingLaptop, $headingMobile;
//$screens -> reference -> _variables.scss
@mixin heading($selectors, $screens) {
	& {
		$index: 1;
		@each $screen in $screens {
			$min: map-get($screen, min-width);
			$max: map-get($screen, max-width);
			$separator: 'and';
			$argsMin: '(min-width: ' + $min + ')';
			$argsMax: '(max-width: ' + $max + ')';
			@if ($min == -1) {
				$params: #{$argsMax};
				@include applyMedia($params, $selectors, $index);
			} @else if ($max == -1) {
				$params: #{$argsMin};
				@include applyMedia($params, $selectors, $index);
			} @else {
				$params: #{$argsMin} #{$separator} #{$argsMax};
				@include applyMedia($params, $selectors, $index);
			}

			$index: $index + 1;
		}
	}
}

//similar to previous
//reference -> _font-style.scss -> $textRegular
//reference -> _font-style.scss -> $textStyle
@mixin texting($selectors, $screens) {
	& {
		$index: 1;
		@each $screen in $screens {
			$min: map-get($screen, min-width);
			$max: map-get($screen, max-width);
			$separator: 'and';
			$argsMin: '(min-width: ' + $min + ')';
			$argsMax: '(max-width: ' + $max + ')';
			@if ($min == -1) {
				$params: #{$argsMax};
				@include applyMediaSimple($params, $selectors, $index);
			} @else if ($max == -1) {
				$params: #{$argsMin};
				@include applyMediaSimple($params, $selectors, $index);
			} @else {
				$params: #{$argsMin} #{$separator} #{$argsMax};
				@include applyMediaSimple($params, $selectors, $index);
			}
			$index: $index + 1;
		}
	}
}

//use for gradient text
/*
p {
	@include text-gradient((260deg, #666, #000));
}
*/
@mixin text-gradient($colors) {
	$direction: nth($colors, 3);
	$from: nth($colors, 1);
	$to: nth($colors, 2);
	&,
	& > * {
		background: linear-gradient($direction, $from, $to);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}

//return font full URL Array for couple formats
//know bug -> incorrect first url params
//used in @mixin fontFace
@function font-extensions($font-path, $font-base-file, $font-weight-name, $font-extensions) {
	$concat: '';
	$count: 0;
	$counter: 0;
	@each $ext in $font-extensions {
		$count: $count + 1;
	}
	@each $extension, $extension-name in $font-extensions {
		$separator: '';
		$counter: $counter + 1;
		@if $counter < $count {
			$separator: ', ';
		}
		$concat: $concat +
			url($font-path + '/' + $font-base-file + '-' + $font-weight-name + '.' + $extension)
			format($extension-name) +
			$separator;
	}
	@return $concat;
}

//create full font-face integration
//not used (currently)

@mixin fontFace(
	$font-path,
	$font-name,
	$font-base-file,
	$font-extensions,
	$font-boldness,
	$font-italic
) {
	@each $font-weight, $font-weight-name in $font-boldness {
		@font-face {
			font-family: $font-name;
			font-weight: $font-weight;
			src: font-extensions($font-path, $font-base-file, $font-weight-name, $font-extensions);
			font-display: swap;
			font-style: normal;
		}
		@if $font-italic {
			@font-face {
				font-family: $font-name;
				font-weight: $font-weight;
				src: font-extensions(
					$font-path,
					$font-base-file,
					$font-weight-name + $font-italic,
					$font-extensions
				);
				font-display: swap;
				font-style: italic;
			}
		}
	}
}

@mixin flex-gap($gap, $reverse: false) {
	& > * {
		@if $reverse == false {
			&:not(:first-child) {
				margin-left: $gap / 2;
			}
			&:not(:last-child) {
				margin-right: $gap / 2;
			}
		} @else {
			&:not(:last-child) {
				margin-left: $gap / 2;
			}
			&:not(:first-child) {
				margin-right: $gap / 2;
			}
		}
	}
}

@mixin flex-gap-right($gap) {
	& > * {
		&:not(:last-child) {
			margin-right: $gap;
		}
	}
}
@mixin optional-at-root($sel) {
	@at-root #{if(not &, $sel, selector-append(&, $sel))} {
		@content;
	}
}

@mixin placeholder {
	@include optional-at-root('::-webkit-input-placeholder') {
		@content;
	}

	@include optional-at-root(':-moz-placeholder') {
		@content;
	}

	@include optional-at-root('::-moz-placeholder') {
		@content;
	}

	@include optional-at-root(':-ms-input-placeholder') {
		@content;
	}
}

@mixin print {
	@media only print {
		@content;
	}
}

@mixin hover {
	@media only screen and (hover: hover) and (pointer: fine) {
		@content;
	}
}

// $screen-xs: 390px;
// $screen-sm: 576px;
// $screen-md: 768px;
// $screen-lg: 992px;
// $screen-xhl: 1080px;
// $screen-xl: 1200px;
// $screen-xxl: 1400px;

//max-width: 575px
@mixin mobile {
	@media only screen and (max-width: #{$screen-sm - 1}) {
		@content;
	}
}

//min-width: 575px and max-width: 767px
@mixin mobile-md-only {
	@media only screen and (min-width: #{$screen-sm - 1}) and (max-width: #{$screen-md - 1}) {
		@content;
	}
}
//max-width: 767px
@mixin mobile-md {
	@media only screen and (max-width: #{$screen-md - 1}) {
		@content;
	}
}
//min-width: 767px and max-width: 1399px
@mixin mobile-md-laptop {
	@media only screen and (min-width: #{$screen-md}) and (max-width: #{$screen-xxl - 1}) {
		@content;
	}
}
//max-width: 991px
@mixin mobile-tablet {
	@media only screen and (max-width: #{$screen-lg - 1}) {
		@content;
	}
}
//max-width: 1079px
@mixin mobile-tablet-xhl {
	@media only screen and (max-width: #{$screen-xhl - 1}) {
		@content;
	}
}

//max-width: 1199px
@mixin mobile-tablet-xl {
	@media only screen and (max-width: #{$screen-xl - 1}) {
		@content;
	}
}

//max-width: 1399px
@mixin mobile-desktop-xxl {
	@media only screen and (max-width: #{$screen-xxl - 1}) {
		@content;
	}
}

//max-width: 1679px
@mixin mobile-desktop-xxxl {
	@media only screen and (max-width: $screen-xxxl) {
		@content;
	}
}
@mixin desktop-xxxl {
	@media only screen and (min-width: $screen-xxxl) {
		@content;
	}
}

//min-width: 576px and max-width: 991px
@mixin tablet {
	@media only screen and (min-width: #{$screen-sm}) and (max-width: #{$screen-lg - 1}) {
		@content;
	}
}

//min-width: 576px and max-width: 1079px
@mixin tablet-xl {
	@media only screen and (min-width: #{$screen-sm}) and (max-width: #{$screen-xhl - 1}) {
		@content;
	}
}

//min-width: 576px and max-width: 1399px
@mixin tablet-laptop {
	@media only screen and (min-width: #{$screen-sm}) and (max-width: #{$screen-xxl - 1}) {
		@content;
	}
}

//min-width: 768px and max-width: 992px
@mixin tablet-tablet-xl {
	@media only screen and (min-width: #{$screen-md}) and (max-width: #{$screen-lg - 1}) {
		@content;
	}
}
//min-width: 768px
@mixin tablet-xl-desktop {
	@media only screen and (min-width: #{$screen-md}) {
		@content;
	}
}

//min-width: 576px
@mixin tablet-desktop {
	@media only screen and (min-width: #{$screen-sm}) {
		@content;
	}
}

//min-width: 992px and max-width: 1399px
@mixin laptop {
	@media only screen and (min-width: #{$screen-lg}) and (max-width: #{$screen-xxl - 1}) {
		@content;
	}
}

//min-width: 1080px and max-width: 1399px
@mixin laptop-xl {
	@media only screen and (min-width: #{$screen-xhl}) and (max-width: #{$screen-xxl - 1}) {
		@content;
	}
}

//min-width: 992px
@mixin laptop-desktop {
	@media only screen and (min-width: #{$screen-lg}) {
		@content;
	}
}

//min-width: 1080px
@mixin laptop-xl-desktop {
	@media only screen and (min-width: #{$screen-xhl}) {
		@content;
	}
}

//min-width: 1200px
@mixin laptop-xxl-desktop {
	@media only screen and (min-width: #{$screen-xl}) {
		@content;
	}
}

//min-width: 1400px
@mixin desktop {
	@media only screen and (min-width: #{$screen-xxl}) {
		@content;
	}
}
